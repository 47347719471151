<template>
  <validation-observer ref="simpleRules">
    <div class="row match-height">
      <div class="col-md-6">
        <div>
          <div class="card p-2">
            <h4 class="font-weight-bolder mb-1">
              รวมรหัสพัสดุ
            </h4>
            <div class="income-order-search order-search w-100">
              <div class="anan-input search-input">
                <div class="anan-input__inner anan-input__inner--normal">
                  <input
                    v-model="search"
                    type="text"
                    placeholder="ค้นหา"
                    class="anan-input__input"
                    @keyup.enter="searchNow()"
                  >
                  <div class="anan-input__suffix">
                    <i class="anan-input__clear-btn anan-icon" />
                    <i class="anan-input__suffix-icon anan-icon">
                      <i class="fal fa-search" />
                    </i>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <b-button
                variant="primary"
                class="mt-1 mr-50"
                @click="searchNow()"
              >
                ค้นหา
              </b-button>

              <b-button
                :variant="!checkAll2 ? 'outline-primary' : 'primary'"
                class="mt-1 mr-50"
                :disabled="!checkAll2"
                @click="MergeParcel()"
              >
                รวมหัสพัสดุ (0/10)
              </b-button>

              <b-button
                variant="primary"
                class="mt-1 mr-50"
                @click="CargoDetail()"
              >
                รายละเอียดข้อมูลโกดัง
              </b-button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div>
          <div class="card p-2">
            <h4 class="font-weight-bolder mb-1">
              รายละเอียดข้อมูลโกดัง
            </h4>

            <div>
              <div class="d-flex align-items-center mb-1">
                <div class="w-50">
                  <p class="mb-0 font-weight-bolder text-right mr-2">
                    Reference
                  </p>
                </div>

                <div class="w-50">
                  31826
                </div>
              </div>

              <div class="d-flex align-items-center mb-1">
                <div class="w-50">
                  <p class="mb-0 font-weight-bolder text-right mr-2">
                    เลขพัสดุ
                  </p>
                </div>

                <div class="w-50">
                  433282318992447
                </div>
              </div>

              <div class="d-flex align-items-center mb-1">
                <div class="w-50">
                  <p class="mb-0 font-weight-bolder text-right mr-2">
                    ตำแหน่ง
                  </p>
                </div>

                <div class="w-50">
                  P62
                </div>
              </div>

              <div class="d-flex align-items-center mb-1">
                <div class="w-50">
                  <p class="mb-0 font-weight-bolder text-right mr-2">
                    วัน/เวลา รับเข้า
                  </p>
                </div>

                <div class="w-50">
                  2023-06-18 18:49:38
                </div>
              </div>

              <div class="d-flex align-items-center mb-1">
                <div class="w-50">
                  <p class="mb-0 font-weight-bolder text-right mr-2">
                    น้ำหนัก รับเข้า
                  </p>
                </div>

                <div class="w-50">
                  0.1 กิโลกรัม
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div>
          <div>
            <div class="card">
              <div class="pl-2 pr-2 pt-1">
                <b-table
                  striped
                  hover
                  responsive
                  :per-page="perPage"
                  :current-page="currentPage"
                  :items="filteredItems"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                >
                  <template #head(checkAll)>
                    <b-form-checkbox
                      v-model="checkAll2"
                      value="true"
                      class="custom-control-primary ml-2"
                      :indeterminate="isIndeterminate"
                      @change="checkAll"
                    />

                  </template>

                  <template #cell(checkAll)="data">
                    <b-form-checkbox
                      v-model="data.item.checkItem"
                      name="check-item"
                      class="ml-2"
                    />
                  </template>
                  <template #cell(detail)="data">
                    <div class="d-flex">
                      <div class="w-50">
                        <p class="mb-0 text-right mr-75">
                          หมายเลขใบสั่งซื้อ
                        </p>
                      </div>
                      <div class="w-50">
                        <small class="text-black">
                          3449984401546176321
                        </small>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div class="w-50">
                        <p class="mb-0 text-right mr-75">
                          รหัสพัสดุ
                        </p>
                      </div>
                      <div class="w-50">
                        <b-link
                          :to="{name : 'admin-tracking-edit', params: {id: data.item.id}}"
                          class="mb-0"
                        >
                          78708961999066
                        </b-link>
                        <i
                          v-clipboard:copy="message"
                          :class="iconClass"
                          @click="handleCopy"
                        />
                      </div>
                    </div>

                    <div class="d-flex">
                      <div class="w-50">
                        <p class="mb-0 text-right mr-75">
                          ล็อต/ลำดับ
                        </p>
                      </div>
                      <div class="w-50">
                        <p class="mb-0 text-black badge badge-warning">
                          รถ
                        </p>
                        <b-link :to="{name : 'asdsad' }">
                          LOT1001
                        </b-link>
                        <small>/1</small>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div class="w-50">
                        <p class="mb-0 text-right mr-75">
                          ประเภท
                        </p>
                      </div>
                      <div class="w-50">
                        <p class="mb-0">
                          -
                        </p>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div class="w-50">
                        <p class="mb-0 text-right mr-75">
                          วลีช่วยจำ (ผู้ดูแล)
                        </p>
                      </div>
                      <div class="w-50">
                        <p class="mb-0">
                          -
                        </p>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div class="w-50">
                        <p class="mb-0 text-right mr-75">
                          หมายเหตุ
                        </p>
                      </div>
                      <div class="w-50">
                        <p class="mb-0">
                          --
                        </p>
                      </div>
                    </div>
                  </template>

                  <template #cell(customerCode)="data">
                    <b-link
                      :to="{name : 'admin-employee-edit', params: {id: data.item.id}}"
                      class="mb-0"
                    >
                      {{ data.item.customerCode }}
                    </b-link>
                  </template>

                  <template #cell(calPrice)="data">
                    <div class="d-flex">
                      <div class="w-50">
                        <div class="mb-0 text-right mr-75">
                          <small>กว้างxยาวxสูง (ซม.) </small>
                        </div>
                      </div>
                      <div class="w-50">
                        <small class="mb-0">
                          (76 x 53 x 26) x 1
                        </small>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div class="w-50">
                        <div class="mb-0 text-right mr-75">
                          <small>น้ำหนัก (กิโล.) </small>
                        </div>
                      </div>
                      <div class="w-50">
                        <small class="mb-0">
                          (3.3) x 1
                        </small>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div class="w-50">
                        <div class="mb-0 text-right mr-75">
                          <small>จำนวน </small>
                        </div>
                      </div>
                      <div class="w-50">
                        <small class="mb-0">
                          {{ data.item.calPrice }}
                        </small>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div class="w-50">
                        <div class="mb-0 text-right mr-75">
                          <small>คิดตามกิโล. </small>
                        </div>
                      </div>
                      <div class="w-50">
                        <p class="mb-0">
                          239.40 ฿
                        </p>
                      </div>
                    </div>
                  </template>

                  <template #cell(tranStatus)="data">
                    <app-timeline>
                      <app-timeline-item
                        v-if="data.item.tranStatus.removed"
                        :title="`${data.item.tranStatus.removed} : นำออกแล้ว`"
                        variant="success"
                      />

                      <app-timeline-item
                        v-if="data.item.tranStatus.payFreight"
                        :title="`${data.item.tranStatus.payFreight} : ชำระค่าขนส่ง`"
                        variant="success"
                      />

                      <app-timeline-item
                        v-if="data.item.tranStatus.cargoTh"
                        :title="`${data.item.tranStatus.cargoTh} : ถึงโกดังไทย`"
                        variant="success"
                      />

                      <app-timeline-item
                        v-if="data.item.tranStatus.exportCn"
                        :title="`${data.item.tranStatus.exportCn} : ออกจากโกดัง`"
                        variant="success"
                      />

                      <app-timeline-item
                        v-if="data.item.tranStatus.cargoCn"
                        :title="`${data.item.tranStatus.cargoCn} : เข้าโกดังจีน`"
                        variant="success"
                      />
                    </app-timeline>
                  </template>

                  <template #cell(bill)="data">
                    <b-link
                      :to="{name : 'admin-tracking-edit', params: {id: data.item.id}}"
                      class="mb-0"
                    >
                      {{ data.item.bill ? data.item.bill : 'ไม่มี' }}
                    </b-link>
                  </template>

                  <template #cell(action)="data">
                    <feather-icon
                      icon="Edit3Icon"
                      class="mr-50 text-primary cursor-pointer"
                      @click="EditType(data.item)"
                    />
                  </template>
                </b-table>

                <div class="demo-spacing-0 d-flex justify-content-end mb-2">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-modal
        id="modal-add-new"
        title="เพิ่มรายการการเงิน"
        ok-title="บันทึก"
        cancel-title="ยกเลิก"
        @ok.prevent="submitAddNew()"
      >
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Customer Code"
            rules="required"
          >
            <label for="customerCode">รหัสลูกค้า : <span class="text-danger">*</span></label>
            <b-form-input
              id="customerCode"
              v-model="customerCode"
              :state="errors.length > 0 ? false:null"
              type="text"
              placeholder="รหัสลูกค้า"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Type"
            rules="required"
          >
            <label for="type">ประเภท : <span class="text-danger">*</span></label>
            <b-form-select
              v-model="type"
              :options="typeOp"
              :state="errors.length > 0 ? false:null"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <label for="detail">รายละเอียด :</label>
          <b-form-textarea
            id="detail"
            v-model="detail"
            placeholder="รายละเอียด"
          />
        </b-form-group>

        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Amount"
            rules="required"
          >
            <label for="amount">จำนวน : <span class="text-danger">*</span></label>
            <b-form-input
              id="amount"
              v-model="amount"
              type="number"
              :state="errors.length > 0 ? false:null"
              placeholder="จำนวน"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <label for="prefix">Prefix :</label>
          <b-form-input
            id="prefix"
            v-model="prefix"
            type="number"
            placeholder="Prefix"
          />
        </b-form-group>

        <b-form-group>
          <label for="prefixNum">Prefix Number :</label>
          <b-form-input
            id="prefixNum"
            v-model="prefixNum"
            type="number"
            placeholder="Prefix Number"
          />
        </b-form-group>
      </b-modal>
    </div>
  </validation-observer>
</template>

<script>
import {
  BFormCheckbox, BButton, BTable, BLink, BFormSelect, BModal, BFormGroup, BFormInput, BFormTextarea, BPagination,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BFormCheckbox,
    BButton,
    BTable,
    BLink,
    BFormSelect,
    BModal,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BPagination,
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      checkAll2: false,
      message: '78708961999066',
      isActive: 'all',
      required,
      selected: null,
      amount: 0,
      detail: '',
      type: null,
      customerCode: '',
      prefix: '',
      prefixNum: '',
      currentPage: 1,
      perPage: 10,
      search: '',
      imagePreviewUrls: [],
      fields: [
        {
          key: 'checkAll', label: '', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'detail', label: 'รายละเอียด', thStyle: { width: '25%' }, thClass: 'text-center',
        },
        {
          key: 'customerCode', label: 'รหัสลูกค้า', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'calPrice', label: 'คำนวณราคา', sortable: false, thStyle: { width: '20%' }, thClass: 'text-center',
        },
        {
          key: 'tranStatus', label: 'สถานะขนส่ง', sortable: false, thStyle: { width: '20%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'setup', label: 'จัดวาง', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'bill', label: 'ออกบิล', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'action', label: 'จัดการ', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ],
      selectedAll: false,
      itemss: [
        {
          id: 1,
          checkItem: '',
          detail: {
            name: 'รายการ 1',
            price: 100,
            amount: 1,
          },
          customerCode: 'karayme68',
          calPrice: 'คำนวณราคา',
          tranStatus: {
            cargoCn: '21/07/2023',
            exportCn: '22/07/2023',
            cargoTh: '27/07/2023',
            payFreight: '28/07/2023',
            removed: 'PAY-6918',
          },
          setup: 'P42',
          bill: 'PAY-6918',
          action: 'จัดการ',
        },

        {
          id: 2,
          checkItem: '',
          detail: {
            name: 'รายการ 1',
            price: 100,
            amount: 1,
          },
          customerCode: 'manit2708',
          calPrice: 'คำนวณราคา',
          tranStatus: {
            cargoCn: '21/07/2023',
            exportCn: '22/07/2023',
            cargoTh: '27/07/2023',
            payFreight: '',
            removed: '',
          },
          setup: 'P42',
          bill: '',
          action: 'จัดการ',
        },

        {
          id: 3,
          checkItem: '',
          detail: {
            name: 'รายการ 1',
            price: 100,
            amount: 1,
          },
          customerCode: 'boycott01',
          calPrice: 'คำนวณราคา',
          tranStatus: {
            cargoCn: '21/07/2023',
            exportCn: '',
            cargoTh: '',
            payFreight: '',
            removed: '',
          },
          setup: 'P42',
          bill: 'PAY-6918',
          action: 'จัดการ',
        },
      ],
      iconClass: 'fal fa-copy text-primary',
    }
  },
  computed: {
    isIndeterminate() {
      const checkedItemsCount = this.itemss.filter(item => item.checkItem).length
      return checkedItemsCount > 0 && checkedItemsCount < this.itemss.length
    },

    filteredItems() {
      if (!this.search) {
      // If the search text is empty, return the original data without filtering
        return this.itemss
      }
      // Filter the data based on the search text
      const searchTerm = this.search.toLowerCase()
      return this.itemss.filter(item => (
        item.customerCode.toLowerCase().includes(searchTerm)
          || item.detail.name.toLowerCase().includes(searchTerm)
      ))
    },
  },
  mounted() {
    this.getData('all')
  },
  methods: {
    checkAll() {
      if (this.checkAll2) {
        this.itemss.forEach(item => {
          this.$set(item, 'checkItem', true)
        })
      } else {
        this.itemss.forEach(item => {
          this.$set(item, 'checkItem', false)
        })
      }
    },
    Claim() {
      this.$swal({
        title: '<h3 style="color: #141414">ทำรายการ Claim สินค้า</h3>',
        icon: 'warning',
        showCancelButton: true,
        text: 'คุณต้องเคลมสินค้าหรือไม่ ?',
        confirmButtonText: 'Yes, claim it!',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: '<h3 style="color: #141414">สำเร็จ</h3>',
            html: '<p style="color: #141414">ทำรายการเคลมสินค้าสำเร็จ</p>',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      })
    },
    removeImage(url) {
      this.imagePreviewUrls = this.imagePreviewUrls.filter(item => item !== url)
    },
    submitAddNew() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const params = {
            customerCode: this.customerCode,
            type: this.type,
            detail: this.detail,
            amount: this.amount,
            prefix: this.prefix,
            prefixNum: this.prefixNum,
          }
          this.$http.post('groupbyuser/list/create', params).then(() => {
            this.getData(this.isActive)
            this.$bvToast.toast('เพิ่มข้อมูลสำเร็จ', {
              title: 'สำเร็จ',
              variant: 'success',
              solid: true,
              autoHideDelay: 1500,
            })
          })
          this.$bvModal.hide('modal-add-new')
        }
      })
    },
    getDataStorage() {
      const data = JSON.parse(localStorage.getItem('data'))
      this.imagePreviewUrl = data.imagePreviewUrl
      this.imagePreviewUrls = data.imagePreviewUrls
      this.colorBG = data.colorBG
      this.colorText = data.colorText
      this.gradientColor = data.gradientColor
    },
    handleFileChanges(event) {
      const { files } = event.target
      this.imagePreviewUrls = []
      console.log(this.imagePreviewUrls)
      //   add to local storage
      localStorage.setItem(
        'imagePreviewUrls',
        JSON.stringify(this.imagePreviewUrls[0]),
      )

      for (let i = 0; i < files.length; i += 1) {
        const reader = new FileReader()
        reader.onload = e => {
          this.imagePreviewUrls.push(e.target.result)
        }
        reader.readAsDataURL(files[i])
      }
    },
    addMoreImages() {
      this.$refs.fileInput.click()
      console.log(this.imagePreviewUrls)
    },
    handleFileChange(event) {
      const file = event.target.files[0]
      this.imgBackground = file
      this.previewImage(file)
    },
    previewImage(file) {
      const reader = new FileReader()
      reader.onload = () => {
        this.imagePreviewUrl = reader.result
      }
      reader.readAsDataURL(file)
    },
    showAddNew() {
      this.$bvModal.show('modal-add-new')
    },
    async getData(type) {
      this.isActive = type
      try {
        const { data: res } = await this.$http.get('/groupbyuser/list/index')
        this.items = res
      } catch (e) {
        console.log(e)
      }
    },
    EditType(data) {
      this.$router.push({ name: 'admin-tracking-edit', params: { id: data.id } })
    },
    DeleteData(data) {
      this.$bvModal.msgBoxConfirm('คุณต้องการลบข้อมูลใช่หรือไม่?', {
        title: 'ยืนยันการลบข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            const params = {
              id: data.id,
            }
            this.$http.post('groupbyuser/list/delete', params).then(() => {
              this.getData(this.isActive)
              this.$bvToast.toast('ลบข้อมูลสำเร็จ', {
                title: 'สำเร็จ',
                variant: 'success',
                solid: true,
                autoHideDelay: 1500,
              })
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleCopy() {
      this.iconClass = 'fal fa-clipboard-check text-success'

      setTimeout(() => {
        this.iconClass = 'fal fa-copy'
      }, 3500)
    },
  },
}
</script>

                <style lang="scss" scoped></style>
